import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';

export const PageTransition: FC<{ children?: ReactNode }> = ({ children }) => {
    return (
        <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
        >
            {children}
        </motion.div>
    );
};
