import styled from "styled-components";
import { rem } from "polished";
import { Container } from "../Container/Container";
import React, { FC } from "react";
import { PageRow } from "../PageRow/PageRow";
import { pxToRem } from "../../utils/pxToRem";
import { PageTransition } from "../PageTransition/PageTransition";

export const Header: FC<{ title: string }> = ({ title }) => {
  return (
    <HeaderWrapper>
      <Container>
        <PageRow>
          <PageTransition>
            <HeaderS>{title}</HeaderS>
          </PageTransition>
        </PageRow>
      </Container>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(250)};
  @media (max-width: ${rem(1280)}) {
    height: ${rem(150)};
  }
  width: 100%;
  background: rgb(0, 166, 156);
  background: linear-gradient(
    90deg,
    rgba(0, 166, 156, 1) 0%,
    rgba(59, 59, 180, 1) 74%,
    rgba(54, 53, 93, 1) 100%
  );
`;

const HeaderS = styled.h1`
  color: var(--color-white);
  font-size: 3rem;
  @media (max-width: ${rem(1280)}) {
    font-size: 2rem;
  }
  margin: 0;
`;
