import React from "react";

import { Container } from "../components/Container/Container";
import { PageRow } from "../components/PageRow/PageRow";
import { PageContentWrapper } from "../components/PageContentWrapper/PageContentWrapper";

const browser = typeof window !== "undefined" && window;
import { navigate } from "gatsby";
import styled from "styled-components";

const NotFoundPage = (): React.ReactNode => {
  return (
    browser && (
      <PageContentWrapper title={"404: Nie odnaleziono strony"}>
        <Container>
          <PageRow>
            <Wrapper>
              <GoBackButton type={"button"} onClick={() => navigate(-1)}>
                Wróć do poprzedniej strony
              </GoBackButton>
            </Wrapper>
          </PageRow>
        </Container>
      </PageContentWrapper>
    )
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const GoBackButton = styled.button`
  padding: 20px 40px;
  border: none;
  background-color: var(--color-primary);
  color: var(--color-white);
  font-weight: 700;
  border-radius: 4px;
  margin: 9rem 0 6.5rem 0;
  cursor: pointer;
`;

export default NotFoundPage;
