import React, { FC } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Container } from '../Container/Container';
import { Header } from '../Header/Header';
import { PageTransition } from '../PageTransition/PageTransition';

type Props = {
    title: string;
    children: React.ReactNode;
};

export const PageContentWrapper: FC<Props> = ({ title, children }) => {
    return (
        <div>
            <Header title={title} />

            {children}
        </div>
    );
};
